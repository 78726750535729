/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { cloneDeep } from 'lodash'
import { useWallet } from 'use-wallet'
import { toast } from 'react-toastify'
import { SUPPORTED_NETWORKS } from 'configs/Connect/Configs.networks'
import { ChainId } from 'configs/Connect/Configs.chainId'
import { onMoveAnimation } from './useDevelopUI'
import { useQuery } from 'react-query'
import { getWeb3 } from './utils/getWeb3'

export const useConnectWallet = () => {
	const wallet = useWallet()
	return async (walletName) => {
		// try {
		//     wallet.connect('injected');
		// } catch (err: any) {
		//     alert(err.message);
		// }
		try {
			await wallet.connect(walletName)
		} catch (err) {
			toast.error(err.message)
		}
	}
}
export const useGetBalance = () => {
	const wallet = useWallet()
	const web3 = getWeb3()
	return useQuery(
		['useGetBalance.name'],
		async () => {
			const balance = await wallet.balance
			return web3.utils.fromWei(web3.utils.toBN(parseInt(balance)))
		},
		{
			refetchInterval: 10000,
		}
	)
}
export const useChangeChain = () => {
	const wallet = useWallet()
	return (chainNum) => {
		if (ChainId.MAINNET === chainNum) {
			try {
				wallet.ethereum?.send('wallet_switchEthereumChain', [{ chainId: '0x1' }, wallet.account])
			} catch (err) {
				toast.error(err?.message)
			}
		} else {
			const params = cloneDeep(
				SUPPORTED_NETWORKS[chainNum || parseInt(process.env.REACT_APP_CHAIN)]
			)
			delete params.chainNumb
			delete params.path
			delete params.pathDisable
			try {
				wallet.ethereum?.send('wallet_addEthereumChain', [params, wallet.account])
			} catch (err) {
				toast.error(err?.message)
			}
		}
	}
}

export const useListenWallet = () => {
	const wallet = useWallet()
	const onChangeChain = useChangeChain()
	React.useEffect(() => {
		if (wallet.status === 'connected') {
			onMoveAnimation('connect-modal', 'moveOutOpacity')
			if (wallet.chainId !== parseInt(process.env.REACT_APP_CHAIN)) {
				onChangeChain(parseInt(process.env.REACT_APP_CHAIN))
				toast.info('Please switch to bsc network')
			}
		} else if (wallet.status === 'error') {
			toast.error(wallet.error?.message)
		}
	}, [wallet.status, wallet.error])
}

export const useCheckChainConnected = () => {
	const wallet = useWallet()
	const [isCompatible, setIsCompatible] = React.useState(false)
	React.useEffect(() => {
		if (wallet.status === 'connected') {
			if (wallet.chainId === parseInt(parseInt(process.env.REACT_APP_CHAIN))) {
				setIsCompatible(true)
			} else {
				setIsCompatible(false)
			}
		}
	}, [wallet.status, wallet.chainId])
	return isCompatible
}

export const useCheckAccountActive = () => {
	const wallet = useWallet()
	return wallet.account
}

export const useDisconnectWallet = () => {
	const wallet = useWallet()
	return () => {
		wallet.reset()
	}
}
