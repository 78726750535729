import transakSDK from '@transak/transak-sdk'
import { ERROR_CODE } from 'constants/error_code'
import { toast } from 'react-toastify'

export const getTransak = (account) => {
	const transak = new transakSDK({
		apiKey: '4fcd6904-706b-4aff-bd9d-77422813bbb7', // Your API Key (Required)
		environment: 'STAGING', // STAGING/PRODUCTION (Required)
		defaultCryptoCurrency: 'BNB',
		walletAddress: account, // Your customer wallet address
		themeColor: '000000', // App theme color in hex
		email: '', // Your customer email address (Optional)
		redirectURL: '',
		hostURL: window.location.origin, // Required field
		widgetHeight: '550px',
		widgetWidth: '450px',
	})
	transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
		transak.close()
	})

	// This will trigger when the user marks payment is made.
	transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
		transak.close()
	})
	return transak
}
export const useCopyToClipBoard = () => {
	return (value) => {
		var textArea = document.createElement('textarea')
		textArea.value = value
		textArea.style.top = '0'
		textArea.style.left = '0'
		textArea.style.position = 'fixed'
		textArea.style.width = '2px'
		textArea.style.opacity = 0

		document.body.appendChild(textArea)
		textArea.focus()
		textArea.select()
		const success = document.execCommand('copy')
		if (success) {
			toast.success('Copied')
		} else {
			toast.error('Something went wrong')
		}
	}
}

export const compactAddress = (address) => {
	if (address && typeof address === 'string') {
		return `${address.substring(0, 4)}...${address.substring(address.length - 4, address.length)}`
	}
	return '...'
}

export const onFormatDateHuman = (date) => {
	const eleDate = new Date(date)
	return eleDate.toLocaleDateString('en-US', {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	})
}

export const formatPathToHuman = (path) => {
	return path.slice(1)
}
export const onShowError = (errId) => {
	let error = ''
	for (let attr in ERROR_CODE) {
		if (ERROR_CODE[attr] === errId) {
			toast.error(attr)
			error = attr
		}
	}
	return error
}
