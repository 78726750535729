import React from 'react'
import { Link } from 'react-router-dom'
import { onTriggerMenu } from 'services/useDevelopUI'
import CloseIcon from '@mui/icons-material/Close'
import './MenuList.css'

export const MenuList = () => {
	return (
		<div onClick={() => onTriggerMenu()} id={'menu'} className='menu-list'>
			<CloseIcon style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 100 }} />
			<div className='list'>
				<Link to='/nft-mint'>
					<div className='item'>Mint Now</div>
				</Link>
				<Link to='/nft-preview'>
					<div className='item'>Avatar Preview</div>
				</Link>

				<div
					className='item cursor-pointer'
					onClick={() => {
						window.open('https://thewastedlands.io/vesting', '_blank')
					}}
				>
					Claim WAL
				</div>
			</div>
		</div>
	)
}
