import React from 'react'
import QRCode from 'react-qr-code'
import { DataContext } from 'contexts/DataContext'
import { onMoveAnimation } from 'services/useDevelopUI'
import { useCopyToClipBoard } from 'services/utils'
import './QrCode.css'

export const QrCode = () => {
	const data = React.useContext(DataContext)
	const onCopy = useCopyToClipBoard()
	return (
		<div id='qr-code' className='qr-form'>
			<div onClick={() => onMoveAnimation('qr-code', 'moveOutOpacity')} className='overlay'></div>
			<div className='qr-code-container'>
				<span className='title'>Scan me</span>
				<div className='qr-container'>
					<QRCode value={data.user?.token || 'Something went wrong'} />
				</div>
				<span className='token w-full hidden-mobile'>{data.user?.token}</span>
				<div
					onClick={() => onCopy(data.user?.token || 'Something went wrong')}
					className='qr-token btn-primary'
				>
					Copy token
				</div>
			</div>
		</div>
	)
}
