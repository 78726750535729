import React from 'react'
import { Link } from 'react-router-dom'
import { onMoveAnimation, onTriggerMenu } from 'services/useDevelopUI'
import {
	useCheckAccountActive,
	useCheckChainConnected,
	useChangeChain,
} from 'services/useWalletProvider'
import { compactAddress } from 'services/utils'
import Logo from 'assets/logo.png'
import MenuIcon from '@material-ui/icons/Menu'
import './Navbar.css'

export const Navbar = () => {
	const [isScroll, setIsScroll] = React.useState(false)
	const accountActive = useCheckAccountActive()
	const isCompatibleChain = useCheckChainConnected()
	const onChangeChain = useChangeChain()
	React.useEffect(() => {
		const onScroll = () => {
			let bodyRect = document?.body?.getBoundingClientRect()?.top
			if (bodyRect < -60) setIsScroll(true)
			else setIsScroll(false)
		}
		// clean up code
		window.removeEventListener('scroll', onScroll)
		window.addEventListener('scroll', onScroll, { passive: true })
		return () => window.removeEventListener('scroll', onScroll)
	}, [])
	return (
		<div className={`navbar ${isScroll ? 'nav-scroll-color' : ''}`}>
			<div className='max-w-screen-xl container-md'>
				<div className='container-left'>
					<Link to='/'>
						<img src={Logo} alt='logo' />
					</Link>
				</div>

				<div className='flex flex-row items-center justify-center'>
					<ul className='list-nav'>
						{/* <li className='nav-link hidden-mobile'>
							<Link to='/?id=ultility'></Link>
						</li> */}
						<li className='nav-link'>
							<Link to='/nft-mint'>Mint Now</Link>
						</li>
						<li className='nav-link'>
							<Link to='/nft-preview'>Avatar Preview</Link>
						</li>
						{/* <li className='nav-link'>
							<Link to='/marketplace'>Marketplace</Link>
						</li> */}
						{/* <li className='nav-link cursor-pointer'>
							<span>
								Marketplace <KeyboardArrowDownIcon />
							</span>
							<div className='nav-hover'>
								<ul>
									<Link to='/marketplace'>
										<li>Warriors marketplace</li>
									</Link>
									<Link to='/frame-market'>
										<li>Equipment marketplace</li>
									</Link>
								</ul>
							</div>
						</li> */}
						{/* <li className='nav-link'>
							<Link to='/?id=benefit'>Benefits</Link>
						</li>

						<li className='nav-link'>
							<Link to='/?id=timeline'>Timeline</Link>
						</li> */}
						<li
							className='nav-link cursor-pointer'
							onClick={() => {
								window.open('https://thewastedlands.io/vesting', '_blank')
							}}
						>
							Claim WAL
						</li>
					</ul>
					{!accountActive ? (
						<div
							onClick={() => onMoveAnimation('connect-modal', 'moveInOpacity')}
							className='btn-primary ml-9'
						>
							Connect
						</div>
					) : isCompatibleChain ? (
						<div
							onClick={() => onMoveAnimation('account-info', 'moveInOpacity')}
							className='btn-primary ml-9'
						>
							{compactAddress(accountActive)}
						</div>
					) : (
						<div
							onClick={() => onChangeChain(parseInt(process.env.REACT_APP_CHAIN))}
							className='btn-primary ml-9'
						>
							Switch
						</div>
					)}
					<MenuIcon onClick={() => onTriggerMenu()} className='icon-menu-bar' />
				</div>
			</div>
		</div>
	)
}
