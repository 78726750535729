import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
	PreLoading,
	Footer,
	Navbar,
	Loading,
	Connect,
	ScrollButton,
	AccountDetail,
	QrCode,
	MenuList,
} from 'components'
import { useListenWallet } from 'services/useWalletProvider'
import { ToastContainer } from 'react-toastify'
import './root.css'
import 'react-toastify/dist/ReactToastify.css'
import { useRouteTopPage, useShowButtonScroll } from 'services/useDevelopUI'
const HomePage = React.lazy(() => import('./pages/Home'))
// const NFTList = React.lazy(() => import('./pages/NFTList'))
const NFTMint = React.lazy(() => import('./pages/NFTMint'))
const NFTPreview = React.lazy(() => import('./pages/NFTPreview'))
const ComingPage = React.lazy(() => import('./components/Coming/Coming'))
function App() {
	useListenWallet()
	useRouteTopPage()
	useShowButtonScroll()
	return (
		<React.Suspense fallback={<PreLoading />}>
			<QrCode />
			<PreLoading />
			<MenuList />
			<Loading />
			<ScrollButton />
			<AccountDetail />
			<Navbar />
			<Connect />
			<Switch>
				<Route component={HomePage} path='/' exact />
				{/* <Route component={NFTList} path='/nft-list' exact /> */}
				<Route component={NFTMint} path='/nft-mint' exact />
				<Route component={NFTPreview} path='/nft-preview' exact />
				<Route component={ComingPage} />
			</Switch>
			<Footer />
			<ToastContainer
				position='bottom-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</React.Suspense>
	)
}

export default App
