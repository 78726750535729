export const ChainId = {
	MAINNET: 1,
	ROPSTEN: 3,
	RINKEBY: 4,
	GÖRLI: 5,
	KOVAN: 42,
	MATIC: 137,
	MATIC_TESTNET: 80001,
	FANTOM: 250,
	FANTOM_TESTNET: 4002,
	XDAI: 100,
	BSC: 56,
	BSC_TESTNET: 97,
	ARBITRUM: 42161,
	ARBITRUM_TESTNET: 79377087078960,
	MOONBEAM_TESTNET: 1287,
	AVALANCHE: 43114,
	AVALANCHE_TESTNET: 43113,
	HECO: 128,
	HECO_TESTNET: 256,
	HARMONY: 1666595000,
	HARMONY_TESTNET: 1666700000,
	OKEX: 66,
	OKEX_TESTNET: 65,
	CELO: 42220,
	PALM: 11297108109,
	PALM_TESTNET: 11297108099,
	MOONRIVER: 1285,
	FUSE: 122,
}
export const ChainIdsArray = [1, 3, 4, 5, 56, 97, 42, 10, 69, 42161, 421611, 137, 80001]
export const CHAINS = {
	1: {
		urls: [
			process.env.infuraKey ? `https://mainnet.infura.io/v3/${process.env.infuraKey}` : undefined,
			process.env.alchemyKey
				? `https://eth-mainnet.alchemyapi.io/v2/${process.env.alchemyKey}`
				: undefined,
			'https://cloudflare-eth.com',
		].filter((url) => url !== undefined),
		name: 'Mainnet',
	},
	3: {
		urls: [
			process.env.infuraKey ? `https://ropsten.infura.io/v3/${process.env.infuraKey}` : undefined,
		].filter((url) => url !== undefined),
		name: 'Ropsten',
	},
	4: {
		urls: [
			process.env.infuraKey ? `https://rinkeby.infura.io/v3/${process.env.infuraKey}` : undefined,
		].filter((url) => url !== undefined),
		name: 'Rinkeby',
	},
	5: {
		urls: [
			process.env.infuraKey ? `https://goerli.infura.io/v3/${process.env.infuraKey}` : undefined,
		].filter((url) => url !== undefined),
		name: 'Görli',
	},
	56: {
		urls: '',
		name: 'BSC',
	},
	97: {
		urls: '',
		name: 'BSC_Testnet',
	},
	42: {
		urls: [
			process.env.infuraKey ? `https://kovan.infura.io/v3/${process.env.infuraKey}` : undefined,
		].filter((url) => url !== undefined),
		name: 'Kovan',
	},
	// Optimism
	10: {
		urls: [
			process.env.infuraKey
				? `https://optimism-mainnet.infura.io/v3/${process.env.infuraKey}`
				: undefined,
			'https://mainnet.optimism.io',
		].filter((url) => url !== undefined),
		name: 'Optimistic Ethereum',
		blockExplorerUrls: ['https://optimistic.etherscan.io'],
	},
	69: {
		urls: [
			process.env.infuraKey
				? `https://optimism-kovan.infura.io/v3/${process.env.infuraKey}`
				: undefined,
			'https://kovan.optimism.io',
		].filter((url) => url !== undefined),
		name: 'Optimistic Kovan',
		blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
	},
	// Arbitrum
	42161: {
		urls: [
			process.env.infuraKey
				? `https://arbitrum-mainnet.infura.io/v3/${process.env.infuraKey}`
				: undefined,
			'https://arb1.arbitrum.io/rpc',
		].filter((url) => url !== undefined),
		name: 'Arbitrum One',
		blockExplorerUrls: ['https://arbiscan.io'],
	},
	421611: {
		urls: [
			process.env.infuraKey
				? `https://arbitrum-rinkeby.infura.io/v3/${process.env.infuraKey}`
				: undefined,
			'https://rinkeby.arbitrum.io/rpc',
		].filter((url) => url !== undefined),
		name: 'Arbitrum Testnet',
		blockExplorerUrls: ['https://testnet.arbiscan.io'],
	},
	// Polygon
	137: {
		urls: [
			process.env.infuraKey
				? `https://polygon-mainnet.infura.io/v3/${process.env.infuraKey}`
				: undefined,
			'https://polygon-rpc.com',
		].filter((url) => url !== undefined),
		name: 'Polygon Mainnet',
		blockExplorerUrls: ['https://polygonscan.com'],
	},
	80001: {
		urls: [
			process.env.infuraKey
				? `https://polygon-mumbai.infura.io/v3/${process.env.infuraKey}`
				: undefined,
		].filter((url) => url !== undefined),
		name: 'Polygon Mumbai',
		blockExplorerUrls: ['https://mumbai.polygonscan.com'],
	},
}

export const chainIDs = [1, 3, 4, 56]
