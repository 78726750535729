import random from 'lodash/random'
const nodes = [
	process.env.REACT_APP_NODE_1,
	process.env.REACT_APP_NODE_1,
	process.env.REACT_APP_NODE_1,
]

export const getNodeUrl = () => {
	const randomIndex = random(0, nodes.length - 1)
	return nodes[randomIndex]
}
