import React from 'react'
import Logo from 'assets/logo.png'
import TelegramIcon from 'assets/home/telegram__icon.png'
import DiscordIcon from 'assets/home/discord__icon.png'
import TwitterIcon from 'assets/home/twitter__icon.png'
import '../../pages/Home/components/Footer/Footer.css'

export const Footer = () => {
	return (
		<div className='w-full flex flex-col items-center'>
			<div className='footer-social__container w-full flex flex-col items-center'>
				<div className='footer-social flex flex-col items-center justify-between w-full max-w-screen-xl'>
					<div className='w-full flex items-center justify-between'>
						<div className=''>
							<img className='logo' src={Logo} alt='Logo' />
						</div>
						<div className='flex items-center justify-end part'>
							<a
								className='content click_telegram flex '
								target='_blank'
								rel='noreferrer'
								href='https://t.me/TheWastedLands'
							>
								<img className='click_telegram' src={TelegramIcon} alt='Telegram icon ml-8' />{' '}
								Telegram
							</a>
							<a
								className='content click_discord flex'
								target='_blank'
								rel='noreferrer'
								href='https://discord.gg/thewastedlands'
							>
								<img className='click_discord ml-8' src={DiscordIcon} alt='Telegram icon ' />{' '}
								Discord
							</a>
							<a
								target='_blank'
								rel='noreferrer'
								className='content click_twiter flex'
								href='https://twitter.com/thewastedlands'
							>
								<img src={TwitterIcon} alt='Telegram icon' className='click_twiter ml-8' /> Twitter
							</a>
						</div>
					</div>
					<div className='social__boder w-full'></div>
					<div className='w-full flex justify-between items-center'>
						<span className='content mr-1'>Copyright © 2021 Wasted Lands</span>
						<div className='flex items-end'>
							<span className='content'>Drop us an email:</span>
							<span className='email-address ml-1'>contact@thewastedlands.io</span>
						</div>
					</div>
					<div className='flex flex-row container-social__2'></div>
				</div>
			</div>
		</div>
	)
}
